
















import {Vue, Component} from "vue-property-decorator";
import PowerBiBTab from "@/components/c-power-bi-iframe/BTab.vue";
import PowerBiIframe from "@/components/c-power-bi-iframe/Iframe.vue";

export interface ISetting {
    code: string,
    title: string,
    src: string,
    width: string,
    height: string,
    allowFullScreen: boolean
}

@Component({
    components: {
        'PowerBiBTab': PowerBiBTab,
        'PowerBiIframe': PowerBiIframe
    }
})

export default class PowerBiTabs extends Vue {
    private powerTabs: ISetting[] = []

    private async load_tabs_list() {
        const response = await fetch('/api-py/get-power-bi-frames-list');
        if (response.status == 200) {
            const result = await response.json();
            for (const pw of result) {
                this.powerTabs.push(pw)
            }
            // this.powerTabs = result;
        }
    }

    private mounted() {
        this.load_tabs_list()
    }
}
