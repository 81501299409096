






import {Vue, Component, Prop} from "vue-property-decorator";
import {BTab} from "bootstrap-vue";
import store from "@/services/store";

@Component({
    components: {
    }
})
export default class PowerBiBTab extends BTab {
    // @Prop() private attr = (this.$attrs)
    // @Prop() private title: string = '';
    @Prop() private allowRegion: string | undefined;
    public isVisible() {
        let result = true;
        if (this.allowRegion) {
            result = (this.allowRegion == store.state._instanceCode);
        } else {
            result = true
        }
        return result
    }
    mounted() {
        this.$emit('visible', this.isVisible())
        // this.hidden = true
    }
}
